
    import { PublicationApiClient, PublicationModel, LinkModel, MemberApiClient } from '../../generated';
    import { defineComponent } from 'vue';
    import { IonIcon, IonImg } from '@ionic/vue';
    import { downloadOutline, heartOutline, caretDownOutline, arrowForwardOutline, heart } from 'ionicons/icons';
    import { store } from '../../store/store';
    import moment from 'moment';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'Publication Detail',
        components: {
            IonIcon,
            IonImg
        },
        mixins: [LanguageMixin],
        watch: {
            '$route.params': {
                handler(newValue) {
                    const { publication } = newValue;
                    this.loadData(publication);
                },
                immediate: true,
            }
        },
        data() {
            return {
                pubId: this.$route.params.publication as string,
                publicationCat: new PublicationModel(),
                categoryLinks: Array<LinkModel>(),
                publications: Array<PublicationModel>(),
                currentLanguage: store.state.currentLanguage as string,
                favoritePublications: localStorage.getItem("favoritePublications"),
                componentKey: 0,
                loggedIn(): boolean {
                    return localStorage.getItem('loggedIn') == 'true';
                }
            }
        },
        ionViewDidEnter() {
            setTimeout(() => {
                this.loadData(this.pubId);
            }, 100);
        },
        setup() {
            function getDateString(date: Date, format: string): string {
                return moment(date).format(format);
            }
            return {
                downloadOutline, heartOutline, caretDownOutline, arrowForwardOutline, getDateString, heart
            }
        },
        methods: {
            forceRerender(child: any) {
                this.componentKey += 1;
            },
            toggleExpand: function (e: number) {
                const expandHeader = document.querySelectorAll('[data-expand="expand-' + e + '"]')[0] as HTMLDivElement,
                    newHeight = expandHeader.nextElementSibling ? expandHeader.nextElementSibling.querySelector('.expand-content')?.clientHeight : null,
                    contentContainer = expandHeader.nextElementSibling as HTMLCanvasElement;

                expandHeader.classList.toggle('active');

                if (expandHeader.nextElementSibling && contentContainer.style.height === '') {
                    contentContainer.style.height = newHeight + 'px';
                } else {
                    contentContainer.style.height = '';
                }

                //closing other accordion items
                if (this.publications.length > 1) {

                    const nonClickedIndexes = this.publications.filter((item) => item.id != e);

                    for (let i = 0; i < nonClickedIndexes.length; i++) {
                        if (nonClickedIndexes[i].id != e) {
                            const closeOtherHeaders = document.querySelectorAll('[data-expand="expand-' + nonClickedIndexes[i].id + '"]')[0],
                                contentContainerClosed = closeOtherHeaders.nextElementSibling as HTMLCanvasElement;

                            closeOtherHeaders.classList.remove('active');
                            contentContainerClosed.style.height = '';
                        }
                    }
                }
            },
            loadData: function (publicationId: string) {
                if (publicationId) {
                    setTimeout(() => {
                        const publicationsClient = new PublicationApiClient();
                        if (publicationId) {
                            publicationsClient.getPublicationMainCategory(parseInt(publicationId), store.state.currentLanguage).then(result => {
                                if (result != null) {
                                    this.publicationCat = result;

                                    if (result.categoryLinks != null) {
                                        this.categoryLinks = result.categoryLinks;
                                    } else {
                                        this.categoryLinks = [];
                                    }
                                    if (result.publications != undefined) {
                                        this.publications = result.publications;
                                        console.log(this.publications)
                                    } else {
                                        this.publications = [];
                                    }
                                }
                            });
                        }
                    }, 50);
                }
               
            },
            setFavorite(publication: PublicationModel) {
                const client = new MemberApiClient();
                client.setFavoritePublication(publication.id);

                if (this.favoritePublications != undefined) {
                    const favArray = this.favoritePublications.split(",");
                    favArray.push(publication.id.toString());
                    this.favoritePublications = favArray.join();
                    localStorage.setItem('favoritePublications', this.favoritePublications);
                }
                // @ts-ignore
                this.loadData(this.$route.params.publication)
            },
            removeFavorite(publication: PublicationModel) {
                const client = new MemberApiClient();
                client.removeFavoritePublication(publication.id);

                if (this.favoritePublications != undefined) {
                    const favArray = this.favoritePublications.split(",");

                    const index = favArray.indexOf(publication.id.toString());
                    if (index > -1) {
                        favArray.splice(index, 1);
                    }

                    this.favoritePublications = favArray.join();
                    localStorage.setItem('favoritePublications', this.favoritePublications);
                }
                // @ts-ignore
                this.loadData(this.$route.params.publication)
            }
        },
        mounted() {
            //setting 'moment' to the right language, default NL
            const language = this.currentLanguage;
            switch (language) {
                case "nl-NL" || "nl-BE":
                    moment.locale('nl');
                    break;
                case "fr-BE" || "fr-LU":
                    moment.locale('fr');
                    break;
                default:
                    moment.locale('nl');
            }
        },
    });